
import ArgachlalCalcComp from './components/argachlal'
import CalcComp from './components/calc'
import ZuragCalcComp from './components/zurag'

const Calc = () => {
    
  return (
    <div className='py-20 md:container mx-4 font-Roboto'>
        <div>
            <h1 className='text-3xl font-Roboto text-center font-bold'>Үнийн мэдээлэл</h1>
            {/* <div className='grid xs:grid-cols-1 md:grid-cols-3 gap-6 mt-20'>
                <CalcComp/>
                <ZuragCalcComp/>
                <ArgachlalCalcComp/>
            </div> */}
            <div className='mt-8'>
              <h1>1кг 2,500 ₮</h1>
              <h1>1м3 300,000 - 350,000₮</h1>
            </div>
            <div>
              <h1 className='font-bold mt-8 text-center'>ТЭЭВРИЙН ЗАРДАЛ БОДОХ АРГАЧЛАЛ</h1>
              <div className='text-sm mt-4'>
                <h1>●	Тусдаа ирсэн болон өөр дэлгүүрийн барааг тус тусад нь бодно</h1>
                <h1>●	Овор ихтэй ууттай куртик, хөнжил гэх мэт зүйлс овор хэмжээнээсээ хамаараад ойролцоогоор 5000₮-10000₮ хооронд байдаг.</h1>
                <h1>●	Гутлын хайрцаг 4000₮-8000₮ хооронд</h1>
                <h1>●	Тээврийн зардал нь тухайн ачааны жин болон "Оврийн жин" хоёрын аль ихээр нь тооцогддог.</h1>
              </div>
              <h1 className='font-bold mt-8 uppercase text-center'>Хайрцагтай барааны оврыг тодорхойлох аргачлал</h1>
                <div className='flex justify-center mt-4'>
                  <img src='../../images/11.jpg'/>
                </div>
                <div>
                  <h1>БАРААНЫ ОВОР</h1>
                  <p>(Урт * Өргөн * Өндөр) / 1000000 * 1м3 үнэлгээ</p>
                  <h1>БАРААНЫ ЖИН</h1>
                  <p>Барааны жин * 1кг үнэлгээ</p>
                  <h1>ЖИНГЭЭР БОДОХ УУ?, ОВРООР БОДОХ УУ?</h1>
                  <div className='flex justify-center mt-4'>
                    <img src='../../images/tomor.png'/>
                  </div>
                  <p>Таны захиалсан бараа харьцангуй хүнд боловч жижиг хэмжээтэй бол жингээр тээврийн зардлыг тооцоолно.
                     (Үүнийг төмөр туухайтай зүйрлэж ойлгож болно.) Хэрэв таны бараа хөнгөн боловч овор хэмжээ нь харьцангуй том байвал овор хэмжээгээр тээврийн зардлыг бодно. 
                     (Үүнийг хөнгөн унадаг дугуйтай зүйрлэж ойлгож болно.)</p>
                  <h1 className='font-bold mt-6'>Анхаарах захиалга</h1>
                  <p className='my-2'>❌Хориотой бараа захиалснаас ӨӨРТ БОЛОН БУСАД ЗАХИАЛАГЧ, КАРГОНД үүссэн аливаа хохирлыг захиалагч бүрэн барагдуулна
                  <p>❗Захиалагч өөрөө эрсдлээ хариуцаж олон тооны ачих буулгах үйлдэл хийхэд эвдэрч болзошгүй шил, толь, хуванцар зэрэг барааг захиалж болох ба захиалсан тохиолдолд дэлгүүрээсээ сав баглааг маш сайн хийхийг сануулах.</p>
                  </p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Calc