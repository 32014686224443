import { Spinner, useToast } from '@chakra-ui/react';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import {BiMessageSquareEdit, BiSolidUser} from 'react-icons/bi'
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../context/auth';
import { getcountsuserprod } from '../../../uri';

const AllOrders = ({data}) => {
    const {currentUser, logout} = useContext(AuthContext);
  const [load, setLoad] = useState(false)
  const [counts, setCounts] = useState()

    useEffect(() => {
        fetchData();
      },[]);
    
      const fetchData = async () => {
        setLoad(true)
        try{
            const res = await axios.get(getcountsuserprod+`?asdf=${currentUser}`)
            setCounts(res.data);
            setLoad(false)
        }catch(err){
            setLoad(false)
            console.log(err.response.data.error);
        }
      }


  return (
    <div className=''>
        <div className='bg-[#274595] rounded-xl p-8 h-full'>
            <div className='flex justify-between '>
                <div className='flex items-center gap-4 flex-justify-between'>
                    <div className='bg-[#F3D080] rounded-full p-2'>
                        <BiSolidUser color='white' size={25}/>
                    </div>
                    <div className='text-white'>
                        <p className='text-opacity-75 text-lg'>Нийт захиалга</p>
                        <h1 className='font-bold text-xl'>Захиалсан бараа</h1>
                    </div>
                </div>
                <div>
                    <div className='bg-slate-100 rounded-full p-2 cursor-pointer hover:bg-slate-300'>
                        <BiMessageSquareEdit color='#274595' size={16}/>
                    </div>
                </div>
            </div>
            <div className='text-white '>
                <p className='text-opacity-75 text-lg mt-4'>Бүртгүүлсэн</p>
                {
                    load?
                    <Spinner/>
                    :
                    <h1 className='font-bold text-3xl'>{counts?.req[0]?.count}</h1>
                }
                
                <p className='text-opacity-75 text-lg mt-4'>Хүлээж авсан</p>
                {
                    load?
                    <Spinner/>
                    :
                    <h1 className='font-bold text-3xl'>{counts?.confirm[0]?.count}</h1>
                }

                <p className='text-opacity-75 text-lg mt-4'>Ирсэн</p>
                {
                    load?
                    <Spinner/>
                    :
                    <h1 className='font-bold text-3xl'>{counts?.irsen[0]?.count}</h1>
                }
            </div>
        </div>
    </div>
  )
}

export default AllOrders