import React, { useContext, useEffect } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    useDisclosure,
    Button,
    ModalCloseButton,
    Spinner,
    useToast
  } from '@chakra-ui/react';
import { delivremoveuri } from '../../uri';
import { AuthContext } from '../../context/auth';
import { useState } from 'react';
import axios from 'axios';

const DelivRemoveReq = ({ callback }) => {
  const toast = useToast();
    const {currentUser} = useContext(AuthContext);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const[loading, setLoading] = useState(false);

    const handleCallback = () => callback();

    const Submit = async () => {
        setLoading(true)
        try{
            const res= await axios.get(delivremoveuri+`?asdf=${currentUser}`)
            setLoading(false)
            handleCallback();
            onClose();
        }catch(err){
            console.log(err)
            setLoading(false)
        }
    };

  return (
    <>
      <Button onClick={onOpen} className='shadow-xl bg-indigo-600'>Хүргэлт цуцлах</Button>

      <Modal isOpen={isOpen} onClose={onClose} size='xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Хүртгэлт цуцлах</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <h1>Та бүртгүүлсэн хүртгэлтээ цуцлахдаа итгэлтэй байна уу ?</h1>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              Болих
            </Button>
              {
                loading?
                <Button colorScheme='green'><Spinner size='sm' className="mr-2"/>Цуцлаж байна...</Button>
                :
                <Button onClick={Submit} colorScheme='green'>Тийм</Button>
              }
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default DelivRemoveReq