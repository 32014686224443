import React from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box,
  } from '@chakra-ui/react'


const ServiceProcedure = () => {
  return (
    <div>
        <div className=''>
            <div className='h-[20vh] bg-juram bg-cover'>
                <div className='container'>
                    <h1 className='font-Roboto font-extrabold text-3xl uppercase pt-6 md:block md:pt-20 text-center'>Тусламж</h1>
                </div>
            </div>
            <div className='xs:block md:container py-16'>
                <div className='mt-4'>
                    <div className='flex items-center ml-4'>
                    <span className=''>1. Таобао хаяг холбох заавар  <a target="_blank" className='mx-4 text-blue-600' href='https://www.facebook.com/groups/darkhancargo/permalink/3848296392118284/?mibextid=uJjRxr'>хичээл үзэх</a></span>
                    </div>
                    <div className='flex items-center ml-4'>
                    <span className=''>2. Pinduoduo  хаяг холбох заавар  <a target="_blank" className='mx-4 text-blue-500' href='https://www.facebook.com/groups/darkhancargo/permalink/3848311955450061/?mibextid=uJjRxr '>хичээл үзэх</a></span>
                    </div>
                    <div className='flex items-center ml-4'>
                    <span className=''>3. Түгээмэл асуулт  <a target="_blank" className='mx-4 text-blue-500' href='https://www.facebook.com/groups/darkhancargo/permalink/3800237773590813/'>хичээл үзэх</a></span>
                    </div>
                    <div className='flex items-center ml-4'>
                    <span className=''>4. Таобао дэлгүүрийн үнэлгээ   <a target="_blank" className='mx-4 text-blue-500' href='https://www.facebook.com/100055442317693/posts/104335788091165/?mibextid=5eVWNK'>хичээл үзэх</a></span>
                    </div>
                    <div className='flex items-center ml-4'>
                    <span className=''>5. Таобао апп.ын цэсний тайлбар  <a target="_blank" className='mx-4 text-blue-500' href='https://www.facebook.com/groups/darkhancargo/permalink/2952124581735474/'>хичээл үзэх</a></span>
                    </div>
                    <div className='flex items-center ml-4'>
                    <span className=''>6. Таалагдсан дэлгүүр болон бараагаа тэмдгэлж авах <a target="_blank" className='mx-4 text-blue-500' href='https://www.facebook.com/groups/darkhancargo/permalink/2952124455068820/'>хичээл үзэх</a></span>
                    </div>
                    <div className='flex items-center ml-4'>
                    <span className=''>7. Бараа хайх болон сонгосон бараагаа сагслах заавар <a target="_blank" className='mx-4 text-blue-500' href='https://www.facebook.com/100055442317693/posts/105650194626391//'>хичээл үзэх</a></span>
                    </div>
                    <div className='flex items-center ml-4'>
                    <span className=''>8. Google translate ашиглаж орчуулах. Одоо апп нь шинэчлэгдээд бүр л амархан болсон. Ерөнхий ажиллах зарчим адилхан <a target="_blank" className='mx-4 text-blue-500' href='https://fb.watch/m34CytKYGR/'>хичээл үзэх</a></span>
                    </div>
                    <div className='flex items-center ml-4'>
                    <span className=''>9. Буцаалт хийх видео заавар<a target="_blank" className='mx-4 text-blue-500' href='https://fb.watch/m34VIGOLfb/'>хичээл үзэх</a></span>
                    </div>
                    <div className='flex items-center ml-4'>
                    <span className=''> 10. Буцаалт хийх зурган заавар<a target="_blank" className='mx-4 text-blue-500' href='https://www.facebook.com/groups/darkhancargo/permalink/3235671660047430/'>хичээл үзэх</a></span>
                    </div>
                    <div className='flex items-center ml-4'>
                    <span className=''> 11. Сагсласан барааг захиалах<a target="_blank" className='mx-4 text-blue-500' href='https://www.facebook.com/100055442317693/posts/109763054215105/'>хичээл үзэх</a></span>
                    </div>
                    <div className='flex items-center ml-4'>
                    <span className=''>12. Алипэй дээр карт холбох<a target="_blank" className='mx-4 text-blue-500' href=' https://www.facebook.com/groups/darkhancargo/permalink/2952067448407854/'>хичээл үзэх</a></span>
                    </div>
                    <div className='flex items-center ml-4'>
                    <span className=''>13. QR төлбөр төлүүлэх<a target="_blank" className='mx-4 text-blue-500' href='https://www.facebook.com/groups/darkhancargo/permalink/3779956308952293/'>хичээл үзэх</a></span>
                    </div>
                    <div className='flex items-center ml-4'>
                    <span className=''>14. Таобао дээр захиалсан барааныхаа кодыг харах <a target="_blank" className='mx-4 text-blue-500' href='https://www.facebook.com/groups/darkhancargo/permalink/3069528443328420/?mibextid=I6gGtw'>хичээл үзэх</a></span>
                    </div>
                    <div className='flex items-center ml-4'>
                    <span className=''> 15. Таобао линк захиалга<a target="_blank" className='mx-4 text-blue-500' href='https://www.facebook.com/groups/darkhancargo/permalink/3649867705294488/?mibextid=S66gvF'>хичээл үзэх</a></span>
                    </div>
                    <div className='flex items-center ml-4'>
                    <span className=''> 16. 1688  линк захиалга<a target="_blank" className='mx-4 text-blue-500' href='https://www.facebook.com/groups/darkhancargo/permalink/3649850538629538/?mibextid=S66gvF'>хичээл үзэх</a></span>
                    </div>
                    <div className='flex items-center ml-4'>
                    <span className=''>17. Pingduoduo  линк захиалга<a target="_blank" className='mx-4 text-blue-500' href='https://www.facebook.com/groups/darkhancargo/permalink/3649877128626879/?mibextid=S66gvF'>хичээл үзэх</a></span>
                    </div>
                    

                </div>
            </div>
        </div>
    </div>
  )
}

export default ServiceProcedure