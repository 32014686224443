import React from 'react'
import AddressCard from './Components/cards'
import AddressCardTom from './Components/cards copy'

const Address = () => {
  return (
    <div className='py-0'>
      <div className='h-[20vh] bg-juram bg-cover'>
          <div className='container'>
              <h1 className='font-Roboto font-extrabold text-3xl uppercase pt-6 md:block md:pt-20 text-center'>Хаяг холбох</h1>
          </div>
      </div>
      <div className='xs:mt-4 md:mt-20 md:container mx-2 pb-20 flex gap-4'>
        <div className='grid xs:grid-cols-1 md:grid-cols-3 gap-4 justify-center'>
          {/* <AddressCard/> */}
          <AddressCardTom/>
        </div>
      </div>
    </div>
  )
}

export default Address
