import { IconButton, Input, Spinner } from '@chakra-ui/react'
import axios from 'axios';
import React, { useState } from 'react'
import { getuseruri } from '../../uri';
import Ezengui from './productCard';
import { FaSearch } from 'react-icons/fa';

const ProductCheck = () => {
    const[tracks, setTracks] = useState([]);
    const[search, setSearch] = useState();
    const [load, setLoad] = useState(false);
    const[empty, setEmpty] = useState(false);
  
    const fetchData = async ()=>{
      setLoad(true)
      try{
        const res= await axios.get(getuseruri+`tracks/homesearch?trackcode=${search}`)
        setTracks(res.data)
        setEmpty(true)
        setLoad(false)
      }catch(err){
        setLoad(false)
        console.log(err)
      }
    };


  return (
    <div className='xs:mx-4 font-Roboto md:mx-40'>
        <div>
            <div className='mt-20 font-bold'>
                <h1 className='text-xl'>Бараа хайх</h1>
                <div className='flex items-center mt-2'>
                    <Input onChange={e=> setSearch(e.target.value)} placeholder='Трак кодоо оруулна уу ' className='mr-2'/>  
                    {
                        load?
                        <IconButton aria-label='Search database' icon={<Spinner />} />
                        :
                        <IconButton onClick={fetchData} aria-label='Search database' icon={<FaSearch />} />
                    }
                </div>
                <div>
                    {tracks.map((track, index) => (
                        <Ezengui
                        key={index}
                        track={track}
                        />
                    ))}
                    {
                    tracks.length == 0 && empty? 
                    <div>Ийм трак кодтой бараа байхгүй байна.</div>
                        :""
                    }
                </div>
            </div>
        </div>
    </div>
  )
}

export default ProductCheck