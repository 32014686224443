import React from 'react'
import Section1 from '../../components/Home/section1'
import Section2 from '../../components/Home/section2'
import HomeLesson from '../../components/Home/homelesson'
import Calc from '../../components/Calc'
import HomeSlider from '../../components/Home/home'
import ProductCheck from '../../components/Home/productCheck'
import CalcComp from '../../components/Calc/components/calc'

const Home = () => {
  return (
    <div className=''>
        {/* <Section1/> */}
        <HomeSlider/>
        <ProductCheck/>
        {/* <Section2/> */}
        <Calc/>
        {/* <HomeLesson/> */}
        <CalcComp/>
    </div>
  )
}

export default Home


