import React, { useState } from 'react'
import {BsJournalText} from 'react-icons/bs'
import {BiPhoneCall} from 'react-icons/bi'
import {Button, Input, Spinner, useToast} from '@chakra-ui/react'
import { HiOutlineMail } from 'react-icons/hi'
import { Link, useNavigate } from 'react-router-dom'
import { AiOutlineArrowLeft } from 'react-icons/ai'
import {RiLockPasswordLine} from 'react-icons/ri'
import axios from 'axios'
import { registerUri } from '../../uri'

const Register = () => {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({ tabaoName:"", email:"", password:"", phoneNo:"" });
  const [err, setErr] = useState();
  const toast = useToast();
  const navigate = useNavigate();

  const HandleSubmit = async e => {
    if(user.tabaoName === "" || user.email === "" || user.password === "" || user.phoneNo === ""){
      return toast({title: 'Мэдээлэл дутуу',description: "Таны мэдээлэл дутуу байна",status: 'error',duration: 9000,isClosable: true,})
    }
    if(user.password.length < 4){
      return toast({title: 'Мэдээлэл дутуу',description: "Таны нууц үг ядаж 6 тэмдэгт агуулах ёстой !",status: 'error',duration: 9000,isClosable: true,})
    }
    setLoading(true)
    e.preventDefault()
    try{
      const res = await axios.post(registerUri, user)
      if(res.status === 200){
        setLoading(false);
        toast({title: 'Амжилттай бүртгүүллээ.',description: "Дархан каргод тавтай морил",status: 'success',duration: 9000,isClosable: true,})
        navigate("/login")
      }
    }catch(err){
      setLoading(false)
      setErr(err.response.data)
    }
  }

  return (
    <div className=''>
      <div className='grid sm:grid-cols-1 md:grid-cols-2'>

        <div className='grid col-span-1 bg-slate-100 px-14 py-8 h-screen hidden md:block'>
          <img className='h-20' src='./logo.png'/>
          <div className='flex justify-center mt-20'>
            <img className='w-96' src='./images/2.png'/>
          </div>
          <div className='mt-20'>
            <h1 className='font-bold font-Roboto text-4xl'>Тавтай морилно уу</h1>
            <p className='text-slate-700 text-lg w-2/3'>Хамгийн хурдан хамгин найдвартай Дархан карго д тавтай морилно уу </p>
          </div>
        </div>

        <div className='grid col-span-1 px-4 pb-8 md:block'>
          <Link to='/' className='flex items-center ml-8 mt-4'>
            <AiOutlineArrowLeft/>
            <h1 className='text-lg ml-2'>Буцах</h1>
          </Link>
          <div className='flex justify-center'>
            <div className='font-Roboto mt-16 md:mt-40 text-center'>
              <h1 className='font-extrabold text-2xl md:text-4xl'>Сайн байна уу ?</h1>
              <p className='mt-4 text-lg'>Та өөрийн мэдээллээ үнэн зөв бөглөж бүртгүүлнэ үү !</p>
              <div className='mt-10'>

                <div className='flex items-center'>
                  <div className='bg-white rounded-md shadow-md p-3'>
                    <BsJournalText size={16} className=''/>
                  </div>
                  <Input value={user.tabaoName}
                  onChange={ e => setUser({...user, tabaoName:e.target.value})} type='text' placeholder='Нэр' className='ml-2 bg-slate-100' bg='gray.100'/>
                </div>

                <div className='flex items-center  mt-3'>
                  <div className='bg-white rounded-md shadow-md p-3'>
                    <HiOutlineMail size={16} className=''/>
                  </div>
                  <Input  value={user.email}
                  onChange={ e => setUser({...user, email:e.target.value})} type='email' placeholder='Имейл хаяг' className='ml-2 bg-slate-100' bg='gray.100'/>
                </div>

                <div className='flex items-center  mt-3'>
                  <div className='bg-white rounded-md shadow-md p-3'>
                    <BiPhoneCall size={16} className=''/>
                  </div>
                  <Input value={user.phoneNo}
                  onChange={ e => setUser({...user, phoneNo:e.target.value})} type='number' placeholder='Утасны дугаар' className='ml-2 bg-slate-100' bg='gray.100'/>
                </div>

                <div className='flex items-center  mt-3'>
                  <div className='bg-white rounded-md shadow-md p-3'>
                    <RiLockPasswordLine size={16} className=''/>
                  </div>
                  <Input value={user.password}
                  onChange={ e => setUser({...user, password:e.target.value})} type='password' placeholder='Нууц үг' className='ml-2 bg-slate-100' bg='gray.100'/>
                </div>

                <p className='text-end text-red-600 font-bold mt-2'>{err}</p>

                <Button onClick={HandleSubmit} className='w-full mt-8 ' textColor='white' bg='#254A9A'>
                  {
                    loading&&
                    <Spinner className='mr-4'/>
                  }
                  Бүртгүүлэх
                </Button>

                <h1 className='flex gap-2 justify-center mt-4'>Хэрэв та бүртгүүлсэн бол <Link to='/login' className='font-bold cursor-pointer'>Нэвтрэх</Link> дарна уу</h1>

              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Register