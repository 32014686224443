import React from 'react'
import {BiCopy} from 'react-icons/bi'
import {AiOutlineDelete} from 'react-icons/ai'
import DateFormat from '../Dateformat'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import { useToast } from '@chakra-ui/react'
import DeleteModal from '../Modal/delete'

const ProductsCard = ({data, callback}) => {
    const toast = useToast();

    function CopyDans(){
        toast({title: 'Трак код хууллаа',status: 'success',duration: 3000,isClosable: true,})
    }

  return (
    <div>
        <div className='bg-slate-100 font-Roboto p-2 shadow-md rounded-xl mb-2 mt-2'>
             <div className='grid grid-cols-2'>
                <div>
                    <div className='flex items-center gap-2'>
                        <CopyToClipboard text={data.trackCode}>
                            <h1 onClick={CopyDans} className='font-extrabold text-lg text-indigo-800'>{data.trackCode}</h1>
                        </CopyToClipboard>
                        <BiCopy/>
                    </div>
                    <div>
                        <p className='text-sm'>{data.note}</p>
                        <p>{data.pay} ₮</p>
                    </div>
                    {
                        data.type === '1'&&
                        <DeleteModal id={data.id} callback={callback}/>
                    }
                    
                </div>
                <div>
                    <div className='flex justify-end'>
                        <div>
                            {
                                data.type === '1'&&
                                <h1 className='text-xs font-bold bg-gray-400 text-white p-1 rounded-md z-100 mt-[-14px]'>Бүртгүүлсэн</h1>
                            }
                            {
                                data.type === '2'&&
                                <h1 className='text-xs font-bold bg-indigo-600 text-white p-1 rounded-md z-100 mt-[-14px]'>Ачигдсан</h1>
                            }
                            {
                                data.type === '3'&&
                                <h1 className='text-xs font-bold bg-yellow-600 text-white p-1 rounded-md z-100 mt-[-14px]'>Ирсэн</h1>
                            }
                            {
                                data.type === '4'&&
                                <h1 className='text-xs font-bold bg-green-800 text-white p-1 rounded-md z-100 mt-[-14px]'>Эзэндээ очсон</h1>
                            }
                            {
                                data.type === '10'&&
                                <h1 className='text-xs font-bold bg-green-500 text-white p-1 rounded-md z-100 mt-[-14px]'>Бүртгэгдсэн хүргэлт</h1>
                            }
                            {
                                data.type === '20'&&
                                <h1 className='text-xs font-bold bg-green-700 text-white p-1 rounded-md z-100 mt-[-14px]'>Баталгаажсан хүргэлт</h1>
                            }
                        </div>
                    </div>
                    <div>
                        <div>
                            <h1 className='uppercase text-xs mt-2'>Огноо</h1>
                            <h1 className='font-bold'> <DateFormat dateString={data.date}/></h1>
                        </div>
                    </div>
                </div>
             </div>
        </div>
    </div>
  )
}

export default ProductsCard