import React, { useContext, useState } from 'react'
import {BsJournalText} from 'react-icons/bs'
import {BiPhoneCall} from 'react-icons/bi'
import {Button, Input, InputGroup, InputLeftElement, Spinner, useToast} from '@chakra-ui/react'
import { HiOutlineMail } from 'react-icons/hi'
import { Link, useNavigate } from 'react-router-dom'
import { AiOutlineArrowLeft } from 'react-icons/ai'
import {RiLockPasswordLine} from 'react-icons/ri'
import { AuthContext } from '../../context/auth'

const Login = () => {
  const [user, setUser] = useState({ email: "", password: "" });
  const [err, setErr] = useState(null)
  const navigate = useNavigate();
  const [loading, setLoading]= useState(false);
  const { login } = useContext(AuthContext);
  const toast = useToast();

  const HandleSubmit = async e => {
    if(user.email === "" || user.password === ""){
      return toast({title: 'Мэдээлэл дутуу',description: "Таны мэдээлэл дутуу байна",status: 'error',duration: 9000,isClosable: true,}) 
    }
    setLoading(true);
    e.preventDefault()
    try{
     await login(user)
      setLoading(false);
      navigate("/account")
    }catch(err){
      setLoading(false);
      setErr(err.response.data)
    }
  }

  return (
    <div className=''>
      <div className='grid sm:grid-cols-1 md:grid-cols-2'>

        <div className='grid col-span-1 bg-slate-100 px-14 py-8 h-screen hidden md:block'>
          <img className='h-20' src='./logo.png'/>
          <div className='flex justify-center mt-20'>
            <img className='w-96' src='./images/3.png'/>
          </div>
          <div className='mt-20'>
            <h1 className='font-bold font-Roboto text-4xl'>Тавтай морилно уу</h1>
            <p className='text-slate-700 text-lg w-2/3'>Хамгийн хурдан хамгин найдвартай Дархан карго д тавтай морилно уу </p>
          </div>
        </div>

        <div className='grid col-span-1 px-4 pb-8 md:block'>
          <Link to='/' className='flex items-center ml-8 mt-4'>
            <AiOutlineArrowLeft/>
            <h1 className='text-lg ml-2'>Буцах</h1>
          </Link>
          <div className='flex justify-center'>
            <div className='font-Roboto mt-16 md:mt-40 text-center'>
              <h1 className='font-extrabold text-2xl md:text-4xl'>Сайн байна уу ?</h1>
              <p className='mt-4 text-lg'>Та өөрийн мэдээллээ үнэн зөв бөглөж бүртгүүлнэ үү !</p>
              <div className='mt-10'>

                <div className='flex items-center  mt-3'>
                  <div className='bg-white rounded-md shadow-md p-3'>
                    <HiOutlineMail size={16} className=''/>
                  </div>
                  <Input value={user.email}
                  onChange={ e => setUser({...user, email:e.target.value})} type='email' placeholder='Имейл хаяг' className='ml-2 bg-slate-100' bg='gray.100'/>
                </div>

                <div className='flex items-center  mt-3'>
                  <div className='bg-white rounded-md shadow-md p-3'>
                    <RiLockPasswordLine size={16} className=''/>
                  </div>
                  <Input value={user.password}
                  onChange={ e => setUser({...user, password:e.target.value})} type='password' placeholder='Нууц үг' className='ml-2 bg-slate-100' bg='gray.100'/>
                </div>

                <p className='text-end text-red-600 font-bold mt-2'>{err}</p>

                <div className='flex justify-end'>
                  <Link to='/forget-password' className='text-end mt-2 text-blue-500 hover:text-blue-700 cursor-pointer font-bold'>Нууц үгээ мартсан ?</Link>
                </div>

                <Button onClick={HandleSubmit} className='w-full mt-8 ' textColor='white' bg='#254A9A'>
                  {
                    loading&&
                    <Spinner className='mr-2'/>
                  }
                  Нэвтрэх
                </Button>

                <h1 className='flex gap-2 justify-center mt-4'>Хэрэв та бүртгэлгүй бол <Link to='/register' className='font-bold cursor-pointer'>Бүртгүүлэх</Link> дарна уу</h1>

              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Login