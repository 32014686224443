import React, { useEffect, useState } from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    useDisclosure,
    AspectRatio,
  } from '@chakra-ui/react'
import {FaPlayCircle} from "react-icons/fa"
import { getContenttouri } from '../../uri'
import axios from 'axios'

const ModalLesson = ({data}) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [load, setLoad] = useState(false)
    const [lesson, setLesson] = useState([])

  useEffect(() => {
    loadProfile();
  }, []);

  const loadProfile = async () => {
    setLoad(true);
    try{
      const res = await axios.get(getContenttouri+data);
      setLesson(res.data);
      console.log(res.data)
      setLoad(false);
    }catch(err){
      console.log(err)
      setLoad(false)
    }
  }

  return (
    <>
      <FaPlayCircle onClick={onOpen} color='white' size={45}/>

      <Modal isOpen={isOpen} onClose={onClose} size='4xl' scrollBehavior='inside'>
        <ModalOverlay/>
        <ModalContent>
          <ModalHeader>Табао сургалт</ModalHeader>
          <ModalCloseButton/>
          <ModalBody>
                {
                  load?
                    <div className='flex justify-center'>
                      <img className='h-20' src='https://miro.medium.com/v2/resize:fit:1400/1*CsJ05WEGfunYMLGfsT2sXA.gif'/>
                    </div>
                  :
                  <>
                  {
                    lesson.map((data, index) => {
                      return(
                          <div className='' key={index}>
                            {
                              data.type === '2'&&
                              <div className='grid justify-center mt-2 font-Roboto font-bold'>
                                <h1>{data.titletext}</h1>
                                <img className='h-96' src={data.content}/>
                              </div>
                            }
                            {
                              data.type === '1'&&
                            <div className='mt-4'>
                            <h1 className='font-Roboto font-bold'>{data.titletext}</h1>
                                <AspectRatio maxW='800px' ratio={1}>
                                  <iframe
                                    title='naruto'
                                    src={data.content}
                                    allowFullScreen
                                  />
                                </AspectRatio>
                            </div>
                            }
                          </div>
                      )
                    })
                  }
                  </>
                }
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              Буцах
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ModalLesson