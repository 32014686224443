import React from 'react'
import {IoMdCopy} from 'react-icons/io'
import {AiOutlineCheckCircle} from 'react-icons/ai'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import { useToast } from '@chakra-ui/react'

const AddressCardTom = ({data}) => {
    const toast = useToast();
    
    function CopyAlert(){
        toast({title: 'Хууллаа',status: 'success',duration: 3000,isClosable: true,})
    }

    return(
        <div className='flex justify-center font-Roboto rounded-md shadow-lg hover:bg-gray-100 text-black w-96 py-4'>
           
           <div className='p-4'>
                <h1 className='uppercase'>ЭРЭЭНИЙ ХАЯГ</h1>
                <div className='mt-4'>
                    <h1>收件人 (Хүлээн авагч)</h1>
                    <div className='flex items-center mt-1'>
                        <AiOutlineCheckCircle size={18} className='mr-2'/>
                        <div className='w-60 bg-gray-200 rounded-md py-1 px-2'>
                            <h1>达尔罕</h1>
                        </div>
                        <CopyToClipboard text="达尔罕">
                            <div onClick={CopyAlert} className='flex items-center cursor-pointer'>
                                <IoMdCopy size={23} />
                                <h1 className=''>copy</h1>
                            </div>
                        </CopyToClipboard>
                    </div>
                </div>
                <div className='mt-4'>
                    <h1>电话 (Утас)</h1>
                    <div className='flex items-center mt-1'>
                        <AiOutlineCheckCircle size={18} className='mr-2'/>
                        <div className='w-60 bg-gray-200 rounded-md py-1 px-2'>
                            <h1>15148656165</h1>
                        </div>
                        <CopyToClipboard text="15148656165">
                            <div onClick={CopyAlert} className='flex items-center cursor-pointer'>
                                <IoMdCopy size={23} />
                                <h1 className=''>copy</h1>
                            </div>
                        </CopyToClipboard>
                    </div>
                </div>
                <div className='mt-4'>
                    <h1>所在地区 (Бүс нутаг)</h1>
                    <div className='flex items-center mt-1'>
                        <AiOutlineCheckCircle size={18} className='mr-2'/>
                        <div className='w-60 bg-gray-200 rounded-md py-1 px-2'>
                            <h1>内蒙古自治区锡林郭勒盟二连浩特市二连浩特市社区建设管理局
                            </h1>
                                                        </div>
                                                        <CopyToClipboard text="内蒙古自治区锡林郭勒盟二连浩特市二连浩特市社区建设管理局
">
                            <div onClick={CopyAlert} className='flex items-center cursor-pointer'>
                                <IoMdCopy size={23} />
                                <h1 className=''>copy</h1>
                            </div>
                        </CopyToClipboard>
                    </div>
                </div>
                <div className='mt-4'>
                    <h1>街道地址 (Хаяг)</h1>
                    <div className='flex items-center mt-1'>
                        <AiOutlineCheckCircle size={18} className='mr-2'/>
                        <div className='w-60 bg-gray-200 rounded-md py-1 px-2'>
                            <h1>花园小区街面楼1106号DC64 Нэр утас</h1>
                        </div>
                        <CopyToClipboard text="花园小区街面楼1106号DC64 Нэр утас
">
                            <div onClick={CopyAlert} className='flex items-center cursor-pointer'>
                                <IoMdCopy size={23} />
                                <h1 className=''>copy</h1>
                            </div>
                        </CopyToClipboard>
                    </div>
                </div>
                <div className='mt-4'>
                    <h1>邮编 (Зип код)</h1>
                    <div className='flex items-center mt-1'>
                        <AiOutlineCheckCircle size={18} className='mr-2'/>
                        <div className='w-60 bg-gray-200 rounded-md py-1 px-2'>
                            <h1>011100</h1>
                        </div>
                        <CopyToClipboard text="011100">
                            <div onClick={CopyAlert} className='flex items-center cursor-pointer'>
                                <IoMdCopy size={23} />
                                <h1 className=''>copy</h1>
                            </div>
                        </CopyToClipboard>
                    </div>
                </div>
                <div className='mt-4'>
                    <h1>Бүтэн хаяг</h1>
                    <div className='flex items-center mt-1'>
                        <AiOutlineCheckCircle size={18} className='mr-2'/>
                        <div className='w-60 bg-gray-200 rounded-md py-1 px-2'>
                            <h1>
                            达尔罕
15148656165
内蒙古自治区锡林郭勒盟二连浩特市二连浩特市社区建设管理局花园小区街面楼1106号DC64 Нэр утас  
011100
                            </h1>
                        </div>
                        <CopyToClipboard text="达尔罕
15148656165
内蒙古自治区锡林郭勒盟二连浩特市二连浩特市社区建设管理局花园小区街面楼1106号DC64 Нэр утас
011100">
                            <div onClick={CopyAlert} className='flex items-center cursor-pointer'>
                                <IoMdCopy size={23} />
                                <h1 className=''>copy</h1>
                            </div>
                        </CopyToClipboard>
                    </div>
                </div>
           </div>

        </div>
    )
}

export default AddressCardTom