import React, { useContext, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import {Button} from '@chakra-ui/react'
import {AiOutlineMenu, AiOutlineClose} from "react-icons/ai"
import { AuthContext } from '../../context/auth'

const NewNavbar = () => {
    const location = useLocation();
    const pathname = location.pathname;
    const [nav, setNav] = useState(false);
    const [shadow, setShadow] = useState(false);
    const navigate = useNavigate();
    const {currentUser} = useContext(AuthContext);

    const handleNav = () => {
        setNav(!nav);
    }

  return (
    <div className={pathname === '/login' || pathname === '/register' || pathname === '/forget-password' ? "font-Roboto hidden":"font-Roboto"}>
        <div className='hidden md:block shadow-md'>
            <div className='flex justify-between p-4 xs:mx-2 md:mx-10 items-center'>
                <div className='flex items-center gap-2'>
                    <img src='../../logo.jpeg' className='h-12' onClick={()=> navigate('/')}/>
                    <h1 className='font-Roboto uppercase font-bold'>Дархан карго</h1>
                </div>
                <div className='font-Roboto text-lg flex items-center gap-4 font-bold'>
                    <Link className='text-blue-600' to='/'>Нүүр</Link>
                    <Link className='text-red-600' to='/address'>Хаяг холбох</Link>
                    <Link className='text-blue-600' to='/help'>Тусламж</Link>
                    <a  target="_blank" href='https://www.facebook.com/groups/darkhancargo/' className='bg-blue-600 rounded-md p-1 text-white text-sm ml-4 cursor-pointer'>Facebook group</a>
                </div>
                {
                    currentUser?
                    <Button onClick={()=> navigate('/account')}>Миний хуудас</Button>
                    :
                    <div className='font-Roboto text-sm flex items-center gap-2'>
                        <Button className='bg-indigo-600 text-white' bg='indigo.600' textColor='white' onClick={()=> navigate('/login')}>Нэвтрэх</Button>
                        <Button onClick={()=> navigate('/register')}>Бүртгүүлэх</Button>
                    </div>
                }
            </div>
        </div>

        <div>
            <div className='flex justify-between mx-4 items-center p-2 md:hidden'>
                <img src='../../logo.jpeg' className='h-8' onClick={()=> navigate('/')}/>
                <div className='flex items-center gap-1'>
                    {
                        currentUser?
                        <Button onClick={()=> navigate('/account')}>Миний хуудас</Button>
                        :
                        <div className='font-Roboto text-sm flex items-center gap-2'>
                            <Button className='bg-indigo-600 text-white' bg='indigo.600' textColor='white' onClick={()=> navigate('/login')}>Нэвтрэх</Button>
                            <Button onClick={()=> navigate('/register')}>Бүртгүүлэх</Button>
                        </div>
                    }
                    <div onClick={handleNav} className='md:hidden cursor-pointer mr-6'>
                        <AiOutlineMenu size={25}/>
                    </div>
                </div>
            </div>

            <div className={nav ? 'md:hidden fixed left-0 top-0 w-full h-screen bg-black/70 z-50' : ''}>
                <div className={ nav ? 'md:hidden fixed left-0 top-0 w-[75%] sm:w-[60%] md:w-[45%] z-50 h-screen bg-white p-10 ease-in duration-300' 
                                    : 'fixed left-[-100%] top-0 p-10 ease-in duration-300'}>
                    <div>
                        <div className='flex w-full items-center justify-between'>
                        <img className=' w-20' src='./logo.jpeg'/>
                            <div onClick={handleNav} className='rounded-full shadow-lg shadow-gray-400 p-3 cursor-pointer'>
                            <AiOutlineClose/>
                            </div>
                        </div>
                    </div>
                    <div className='py-4 flex flex-col font-bold text-lg'>
                        <Link className='text-blue-600' to='/'>Нүүр</Link>
                        <Link className='text-red-600' to='/address'>Хаяг холбох</Link>
                        <Link className='text-blue-600' to='/help'>Тусламж</Link>
                        <a  target="_blank" href='https://www.facebook.com/groups/darkhancargo/' className='bg-blue-600 rounded-md p-1 text-white text-sm mt-4 cursor-pointer'>Facebook group</a>
                    </div>
                </div>
            </div>
        </div>

    </div>
  )
}

export default NewNavbar