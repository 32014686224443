import React, { useState } from 'react'
import { Button, Input } from '@chakra-ui/react'

const CalcComp = () => {
    const [path, setPath] = useState(1)
    const [price, setPrice] = useState({ereen:2500, gunj:1000, yuanereen:350000, yuangunj:500})
    const [size, setSize] = useState({ length:"", width:"",  heigth:"", kg:""});  //urt orgon ondor

    const [niilber, setNiilber] = useState({kg:"", m3:""})

    function HandleFunction() {
        const sizes = (size.length * size.width * size.heigth) / 1000000;
        
        let b, c;
        if (path === 1) {
          b = sizes * price.yuanereen;
          c = price.ereen * size.kg;
        }
        if (path === 2) {
          b = sizes * price.yuangunj;
          c = price.gunj * size.kg;
        }
      
        let result = Math.max(b, c); 
        if (Number.isFinite(result)) {
            result = parseFloat(result.toFixed(2));
        }
        return result;
    }

  return (
    <div className='xs:mx-4 mx-2 md:mx-40 mb-10'>
        <div>
            {/* <div className='flex items-center gap-2'>
                <div onClick={() => setPath(1)} class={path === 1 ? "relative inline-flex cursor-pointer items-center justify-center px-6 py-2 overflow-hidden font-mono font-medium tracking-tighter text-white bg-indigo-600 rounded-full group"
                :"relative inline-flex items-center justify-center cursor-pointer px-6 py-2 overflow-hidden font-mono font-medium tracking-tighter text-black hover:text-white bg-gray-300 rounded-full group"}>
                    <span class="absolute w-0 h-0 transition-all duration-500 ease-out bg-indigo-500 rounded-full group-hover:w-56 group-hover:h-56"></span>
                    <span class="absolute inset-0 w-full h-full -mt-1 rounded-full opacity-30 bg-gradient-to-b from-transparent via-transparent to-gray-100"></span>
                    <span class="relative">Эрээн агуулах</span>
                </div>
                <div onClick={() => setPath(2)} class={path === 2 ? "relative inline-flex cursor-pointer items-center justify-center px-6 py-2 overflow-hidden font-mono font-medium tracking-tighter text-white bg-indigo-600 rounded-full group"
                :"relative inline-flex items-center justify-center cursor-pointer px-6 py-2 overflow-hidden font-mono font-medium tracking-tighter text-black hover:text-white bg-gray-300 rounded-full group"}>
                    <span class="absolute w-0 h-0 transition-all duration-500 ease-out bg-indigo-500 rounded-full group-hover:w-56 group-hover:h-56"></span>
                    <span class="absolute inset-0 w-full h-full -mt-1 rounded-full opacity-30 bg-gradient-to-b from-transparent via-transparent to-gray-100"></span>
                    <span class="relative">Гуанжу агуулах</span>
                </div>
            </div> */}
            <p className='text-xs font-semibold text-center mt-2'>Та барааны овор болон жингээ оруулан тээврийн зардалаа урьдчилан тооцоолох боломжтой.</p>
            <p className='text-xs font-semibold text-center mt-2 text-red-600'>Энэ зөвхөн ойролцоо үнийн дүн харуулна</p>
            <div className='flex items-center gap-8 mt-4 ml-4'>
                <div>
                    <h1>1кг</h1>
                    <h1>1м3</h1>
                </div>
                {
                    path === 1 &&
                    <div className='font-semibold'>
                        <h1>{price.ereen}</h1>
                        <h1>{price.yuanereen}</h1>
                    </div>
                }
                {
                    path === 2 &&
                    <div className='font-semibold'>
                        <h1>{price.gunj}</h1>
                        <h1>{price.yuangunj}¥</h1>
                    </div>
                }
            </div>
            <div className='mt-4'>
                <div className='flex gap-2'>
                    <Input onChange={(e) => setSize({...size, length:e.target.value})} type='number' className='py-2 border-gray-700' py={2} placeholder='Урт (см)'/>
                    <Input onChange={(e) => setSize({...size, width:e.target.value})} type='number' className='py-2' py={2}  placeholder='Өргөн (см)'/>
                    <Input onChange={(e) => setSize({...size, heigth:e.target.value})} type='number' className='py-2' py={2}  placeholder='Өндөр (см)'/>
                </div>
                <Input onChange={(e) => setSize({...size, kg:e.target.value})} type='number' className='py-2 mt-2'  placeholder='Жин (кг)' py={2}/>
                <Button cursor='ibeam' className='w-full mt-2 bg-indigo-600' colorScheme='indigo.600' size='lg'>Төлөх дүн : {HandleFunction()}</Button>
            </div>
        </div>
    </div>
  )
}

export default CalcComp