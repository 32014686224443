import React, { useContext, useState } from 'react'
import {Button, Input, useToast} from '@chakra-ui/react'
import { HiOutlineMail } from 'react-icons/hi'
import { Link, useNavigate } from 'react-router-dom'
import { AiOutlineArrowLeft } from 'react-icons/ai'
import { AuthContext } from '../../context/auth'
import axios from 'axios'
import { forgetUri } from '../../uri'

const ResetPass = () => {
  const [user, setUser] = useState({ email: ""});
  const [err, setErr] = useState(null)
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);
  const toast = useToast()

  const { login } = useContext(AuthContext);

  const HandleSubmit = async e => {
    setLoad(true)
    e.preventDefault()
    try{
     const res = await axios.post(forgetUri, {email: user.email})
     console.log(res.status)
     if(res.status === 200){
        setLoad(false)
        navigate("/login")
        toast({title: 'Баталгаажуулах линк илгээлээ',description: "Та имейл хаягаа шалгана уу",status: 'success',duration: 5000,isClosable: true,})
     }else{
      setLoad(false)
      toast({title: 'Алдаа',description: "Ямар нэг алдаа гарлаа та админд хандана уу",status: 'error',duration: 3000,isClosable: true,})
     }
    }catch(err){
      setLoad(false)
      setErr(err.response.data)
    }
  }


  return (
    <div className=''>
      <div className='grid sm:grid-cols-1 md:grid-cols-2'>

        <div className='grid col-span-1 bg-slate-100 px-14 py-8 h-screen hidden md:block'>
          <img className='h-20' src='./logo.png'/>
          <div className='flex justify-center mt-20'>
            <img className='w-96' src='./images/2.png'/>
          </div>
          <div className='mt-20'>
            <h1 className='font-bold font-Roboto text-4xl'>Тавтай морилно уу</h1>
            <p className='text-slate-700 text-lg w-2/3'>Хамгийн хурдан хамгин найдвартай Дархан карго д тавтай морилно уу </p>
          </div>
        </div>

        <div className='grid col-span-1 px-4 pb-8 md:block'>
          <Link to='/' className='flex items-center ml-8 mt-4'>
            <AiOutlineArrowLeft/>
            <h1 className='text-lg ml-2'>Буцах</h1>
          </Link>
          <div className='flex justify-center'>
            <div className='font-Roboto mt-16 md:mt-40 text-center'>
              <h1 className='font-extrabold text-2xl md:text-4xl'>Сайн байна уу ?</h1>
              <p className='mt-4 text-lg'>Та өөрийн имейл хаягаа зөв оруулан имейл хүлээж авна уу</p>
              <div className='mt-10'>

                <div className='flex items-center  mt-3'>
                  <div className='bg-white rounded-md shadow-md p-3'>
                    <HiOutlineMail size={16} className=''/>
                  </div>
                  <Input value={user.email} onChange={ e => setUser({...user, email:e.target.value})} type='email' placeholder='Имейл хаяг' className='ml-2 bg-slate-100' bg='gray.100'/>
                </div>
                <div className='text-sm font-Roboto font-bold mt-2 text-end text-red-600'>{err}</div>

                {
                  load?
                  <Button className='w-full mt-8 ' textColor='white' bg='#254A9A'>Илгээж байна ...</Button>
                  :
                  <Button onClick={HandleSubmit} className='w-full mt-8 ' textColor='white' bg='#254A9A'>Илгээх</Button>
                }

                <h1 className='flex gap-2 justify-center mt-4'>Хэрэв та бүртгэлгүй бол <Link to='/login' className='font-bold cursor-pointer'>Нэвтрэх</Link> дарна уу</h1>

              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default ResetPass